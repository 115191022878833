<template>
    <div>
        <top-layer></top-layer>
        <div class="main">
            <center-layer style="top:-520px"></center-layer>
            <div class="container">
                <div class="container-top">
                    <div class="container-top-text">
                        <p style="font-size:32px;font-weight:bold">安全第一&nbsp;服务至上</p>
                        <p style="font-size:24px;font-weight:bold">四川柒鑫物流有限公司是经国家主管机构批准成立的一家大型物流企业。</p>
                        <p style="line-height:26px;font-size:14px">由柒家资深老牌物流公司，通过优化整合，共同组建的一家物流企业，总部位于成都市新都区三河街道蓉香路160号缇香强四栋，拥有庞大的服务网点及强有力的物流配套服务，公司实行现代科技企业制度，规范经营，科学管理，致力打造适合国情而又接轨于世界的创新型科技企业!</p>
                    </div>
                    <div class="container-top-img">
                        <img src="../image/company.png">
                    </div>
                </div>
                <div class="container-bottom">
                    <div style="line-height:50px;padding-left:130px">4月柒鑫物流公司成立，是由金堂县招商引资的企业</div>
                    <div style="line-height:50px">3月成为成都物流协会会员单位/8月被评为三A级物流企业</div>
                    <div>4月荣获《质量管理认证证书》/6月荣获《改革开放四十年--成都物流优秀企业》授牌</div>
                    <div style="padding-left:130px">6月荣获《先进基层党组织》光荣称号/8月荣获《2020年纳税信用评价等级A级》/10月公司搬迁</div>
                </div>
            </div>
        </div>
        <bottom-layer></bottom-layer>
    </div>
</template>
<script>
import topLayer from '../components/top.vue'
import bottomLayer from '../components/bottom.vue'
import centerLayer from '../components/center.vue'
export default {
    components: {
        topLayer,
        bottomLayer,
        centerLayer
    }

}
</script>
<style lang="css" scoped>
.main {
    height: 100%;
    width: 100%;
    /* background: wheat; */
    position: relative;
    background: url(../image/banner.jpg) no-repeat;
    background-size: 100% 700px;
    padding-top: 520px;
    box-sizing: border-box;
}
.banner {
    width: 100%;
    height: 100%;
}
.banner img {
    width: 100%;
}
.container {
    width: 100%;
    /* position: absolute; */
    padding: 100px 15% 0 15%;
    box-sizing: border-box;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    background: white;
}
.container .container-top {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}
.container .container-top .container-top-text {
    width: 40%;
    text-align: left;
}
.container .container-top .container-top-img {
    /* height: 100px;
    width: 100px; */
    width: 50%;
    /*background: wheat;*/
}
.container .container-top .container-top-img img{
    width:100%;
    height:auto;
}
.container .container-bottom {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
.container .container-bottom div {
    width: 50%;
    height: 50px;
    text-align: left;
    position: relative;
    /* border: 1px solid; */
    box-sizing: border-box;
    padding-left: 50px;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: bold;
}
.container .container-bottom div::before {
    height: 50px;
    width: 50px;
    content: '2014';
    background: rgba(3, 93, 252, 1);
    position: absolute;
    left: -15px;
    border-radius: 100%;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    color: white;
}
.container .container-bottom div:nth-child(1):before {
    left: 65px;
}
.container .container-bottom div:nth-child(2):before {
    content: '2017';
}.container .container-bottom div:nth-child(3):before {
    content: '2019';
}
.container .container-bottom div:nth-child(4):before {
    left: 65px;
    content: '2020';
}
</style>
